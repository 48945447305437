@charset "UTF-8";
*:not(input) {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
}

html {
  background-color: white;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  color: #333;
  vertical-align: baseline;
}

header,
footer,
article,
section,
aside,
hgroup,
nav,
menu,
figure,
figcaption,
time {
  display: block;
}

@font-face {
  font-family: 'ヒラギノ角ゴ Pro W3';
  src: local('ヒラギノ角ゴ Pro W3'), local('Hiragino Sans W3'),
    local('ヒラギノ角ゴシック W3');
  font-weight: normal;
}

@font-face {
  font-family: 'ヒラギノ角ゴ Pro W3';
  src: local('ヒラギノ角ゴ Pro W5'), local('Hiragino Sans W5'),
    local('ヒラギノ角ゴシック W5');
  font-weight: 500;
}

@font-face {
  font-family: 'ヒラギノ角ゴ Pro W3';
  src: local('ヒラギノ角ゴ Pro W6'), local('Hiragino Sans W6'),
    local('ヒラギノ角ゴシック W6');
  font-weight: bold;
}

body {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ',
    'Meiryo', verdana, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', Sans-Serif;
  line-height: 1.5;
  word-break: break-all;
  font-size: 1.2rem;
}

li {
  list-style: none;
}

ol li {
  list-style: decimal;
}

img {
  width: 100%;
  height: auto;
  border: 0;
  vertical-align: top;
  font-size: 0;
  line-height: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  margin: 1em 0;
  padding: 0;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
}

input,
select {
  vertical-align: middle;
}

input,
textarea {
  margin: 0;
  padding: 0;
}

a {
  color: #333;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

a.kabucom-link {
  color: #ff6400;
  text-decoration: underline;
}

html {
  font-size: 10px;
}

/* common css */

span.hidden {
  visibility: hidden;
}

.l-notes01 {
  margin: 20px;
}

.l-notes01 p {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 0 8px;
}

.l-notes01 li {
  position: relative;
  padding: 0 0 0 1.2rem;
}

.l-notes01 li:before {
  content: '・';
  position: absolute;
  top: 0;
  left: 0;
}

.l-price01 {
  margin: 20px;
}

.l-price01__container {
  background: #eee;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
}

.l-price01__head {
  padding: 0 0 8px;
  border-bottom: 1px solid #999;
}

.l-price01__head__ttl {
  display: inline-block;
  font-size: 1rem;
  padding: 0 2px;
  margin: 0 0 2px;
  border: 1px solid #333;
  border-radius: 5px;
}

.l-price01__head__txt a {
  font-size: 1.4rem;
  color: #ff6400;
  text-decoration: underline;
}

.l-price01__body {
  padding: 8px 0 0;
}

.l-price01__body__ttl {
  font-size: 1.3rem;
}

.l-price01__body__price {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: right;
  margin: 0 0 8px;
}

.l-price01__body__price span {
  display: block;
  font-size: 1rem;
  font-weight: normal;
  color: #999;
}

.l-price01__body__date {
  margin: 0 0 8px;
}

.l-price01__body__date dl {
  display: flex;
  justify-content: space-between;
}

.l-price01__body__date dt,
.l-price01__body__date dd {
  font-size: 1.4rem;
}

.l-price01__body__list li {
  position: relative;
  padding: 0 0 0 1.2rem;
}

.l-price01__body__list li:before {
  content: '・';
  position: absolute;
  top: 0;
  left: 0;
}

.l-price02 {
  margin: 20px;
}

.l-price02 .l-notes01 {
  margin: 0;
  margin-top: 20px;
}

.l-price02__head {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 0 20px;
}

.l-price02__body__num {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 20px);
  height: 58px;
  background: #eee;
  padding: 0 10px;
  margin: 0 0 8px;
  box-sizing: border-box;
}

.l-price02__body__num p {
  font-size: 1.6rem;
}

.l-price02__body__num > div {
  font-size: 1.6rem;
  text-align: right;
}

.l-price02__body__price {
  display: flex;
  align-items: flex-end;
  font-size: 1.6rem;
  margin: 0 0 4px;
}

.l-price02__body__price input {
  width: calc(100% - 20px);
  height: 58px;
  font-size: 1.6rem;
  text-align: right;
  padding: 0 10px;
  margin: 0 5px 0 0;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
}

.l-price02__body__price input::placeholder {
  color: #999;
}

.l-price02__body__caption {
  color: #444;
  margin: 0 0 8px;
}

.l-price02__body__caption p.nisa-allowance {
  font-weight: bold;
  text-align: center;
}

.l-price02__body__points {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 20px);
  background: #eee;
  padding: 5px 10px;
  box-sizing: border-box;
}

.l-price02__body__points p {
  font-size: 1.6rem;
}

.l-price02__body__points label {
  font-size: 1.6rem;
}

.l-price02__body__points input.numeric {
  width: 80px;
  height: 100%;
  font-size: 1.6rem;
  text-align: right;
  padding: 0 5px;
  margin: 0 0 0 10px;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
}

.l-price02__body__points input.numeric::placeholder {
  color: #999;
}

.l-price02__body__points span {
  font-size: 1.2rem;
  font-weight: normal;
}

.l-link03 {
  margin: 20px;
}

.l-link03__ttl {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 0 20px;
}

.l-link03__list {
  display: flex;
  justify-content: center;
  margin: 0;
}

.l-link03__list li {
  width: 36.6666%;
  margin: 0 8px;
  box-sizing: border-box;
  max-width: 120px;
}

.l-link03__list li button {
  position: relative;
  display: block;
  padding-top: 100%;
  border: 2px solid #999;
  border-radius: 10px;
  box-sizing: border-box;
  width: -webkit-fill-available;
  width: -moz-available;
  outline: 0;
  background-color: white;
}

.l-link03__list li.disabled button {
  background-color: #eee;
  opacity: 0.3;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.l-link03__list img {
  max-width: 50%;
  margin: 0 0 4px;
}

.l-link03__list span {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 1.2rem;
  color: #999;
  margin: auto;
  white-space: pre;
}

.l-link03__list .in-active button {
  background: #ff6400;
  border: 2px solid #ff6400;
}

.l-link03__list .in-active span {
  color: #fff;
}

.l-button02 {
  display: flex;
  justify-content: center;
  margin: 20px;
  align-items: center;
}

.l-button02 button {
  max-width: 280px;
  width: 100%;
  height: 100%;
  min-height: 46px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  background-color: #e50000;
  border: none;
}

.l-button02 button:disabled:not(.-is-ordering) {
  background-color: #eee;
  color: #999;
}

.l-button02 button.-short {
  max-width: 250px;
}

@media only screen and (max-width: 360px) {
  .l-button02 button.-short {
    max-width: 205px;
  }
}

.l-content01 {
  margin: 20px;
}

.l-content01 .l-notes01 {
  margin: 0;
  margin-top: 20px;
}

.l-content01__head {
  font-size: 1.4rem;
  font-weight: bold;
}

.l-content01__body__block {
  padding: 16px 0;
  border-bottom: 1px solid #c2c2c2;
}

.l-content01__body__ttl {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 0 8px;
}

.l-content01__body__content {
  margin-left: 8px;
}

.l-content01__body__detail dd {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: right;
}

.l-content01__body__detail span {
  display: block;
  font-size: 1rem;
  font-weight: normal;
  color: #999;
}

.l-content01__body__num {
  margin: 0 0 16px;
}

.l-content01__body__num p:nth-child(1) {
  margin: 0 0 4px;
}

.l-content01__body__num p:nth-child(2) {
  display: inline-block;
  font-size: 1rem;
  padding: 0 2px;
  margin: 0 0 2px;
  border: 1px solid #333;
  border-radius: 5px;
}

.l-content01__body__num a {
  font-size: 1.2rem;
  color: #ff6400;
  text-decoration: underline;
}

.l-content01__body__link {
  margin: 16px 0;
}

.l-content01__body__link dl {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}

.l-content01__body__link dt a {
  color: #ff6400;
  text-decoration: underline;
}

.l-content01__body__link dd {
  display: flex;
  align-items: center;
}

.l-content01__body__link dd span {
  align-items: center;
  min-width: 60px;
  text-align: end;
}

.l-content01__body__link dd span.confirmed {
  color: #48be2d;
}

.l-content01__body__link img {
  width: 12px;
  margin: 2px 5px 0 0;
}

.l-content01__body__list {
  margin: 16px 0 0;
}

.l-content01__body__list li {
  position: relative;
  padding: 0 0 0 1.2rem;
}

.l-content01__body__list li:before {
  content: '・';
  position: absolute;
  top: 0;
  left: 0;
}

.l-content01__body__password input {
  width: calc(100% - 20px);
  height: 50px;
  font-size: 1.6rem;
  padding: 0 10px;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
}

.l-content01__body__password input::placeholder {
  color: #999;
}

.l-content01__body__password span {
  display: block;
  color: #df0000;
  margin: 4px 0 0;
}

.l-content01__body__points__breakdown {
  width: 100%;
  height: 40px;
}

.l-content01__body__points__breakdown__inner {
  font-size: 1.4rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: end;
  float: right;
}

.l-content01__body__points__breakdown__inner div:nth-child(2) {
  font-weight: bold;
}

.l-login01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.l-login01__head {
  width: 60px;
  margin: 0 0 20px;
}

.l-login01__body p {
  text-align: center;
}

.l-login01__body p:nth-child(1) {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0 0 8px;
}

.-error {
  color: red;
}

.-error * {
  color: red;
}

input.-error {
  background-color: rgb(255, 229, 229);
  border: 1px red solid;
}

input.-error:focus {
  outline: 1px red solid;
}

.error-message {
  color: red;
  margin-left: 45px;
  white-space: break-spaces;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: rgba(124, 124, 124, 0.5);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
